import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Sections
import Hero from "./hero"


// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
import PostItem from "components/resource-list"

const StyledContainer = styled(Container)`
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;
`

const StyledPublicationList = styled.section`
	padding: 28px 0;

	${breakpoint.medium`
		padding: 54px 0;
	`}
`

const PublicationList = props => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: {categories: {nodes: {elemMatch: {slug: {eq: "press-release"}}}}}
        sort: {order: DESC, fields: date}
      ) {
        nodes {
          id
          slug
          date(formatString: "MMMM D, YYYY")
          year: date(formatString: "YYYY")
          dateGmt
          modifiedGmt
          title
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
  `)
  return (
    <StyledPublicationList>
      <StyledContainer>
        <div className="fullList">
          {data.allWpPost.nodes.map((post) => (
            <PostItem
              resourceType="post"
              key={post.id}
              link={"/news/" + post.slug + "/"}
              linkText="Read More"
              title={post.title}
              excerpt={post.excerpt}
              year={post.year}
              image={
                post.featuredImage
                  ? post.featuredImage.node.localFile
                  : false
              }
              publicURL={
                post.featuredImage
                  ? post.featuredImage.node.localFile.publicURL
                  : false
              }
              date={post.date}
              dateGmt={post.dateGmt}
              modifiedGmt={post.modifiedGmt}
              toggleFunction={props.toggleFunction}
              className="visible"
            />
          ))}
        </div>
      </StyledContainer>
    </StyledPublicationList>
  )
}

class Publications extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: "all",
		}
	}

	componentDidMount() {
		var filtby = this.state.filter;
		if (this.props.filterby)
			filtby = this.props.filterby;

		this.handleFilterChange("news", filtby, null);
	}

	handleFilterChange = (type, value, event) => {
		const inactiveFilters = document.querySelectorAll("[data-filter]")
		inactiveFilters.forEach(filter => {
			filter.classList.remove("active")
		})

		const activeFilter = document.querySelector(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilter.classList.add("active")

		if (value === "all") {
			const elementsToFilter = document.querySelectorAll(
				"[data-filter-type='" + type + "']"
			)

			elementsToFilter.forEach(post => {
				post.classList.add("visible")
			})
		} else {
			const elementsToFilter = document.querySelectorAll(
				"[data-filter-type='" + type + "'][data-filter='" + value + "']"
			)
			const previousElements = document.querySelectorAll(
				"[data-filter-type='" + type + "']"
			)

			previousElements.forEach(post => {
				post.classList.remove("visible")
			})

			elementsToFilter.forEach(post => {
				post.classList.add("visible")
			})
		}
	}

	handleToggleChange = (type, value, event) => {

		const activeFilters = document.querySelectorAll(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilters.forEach(filter => {
			filter.classList.toggle("active")
		})
	}


	render = () => (
		<React.Fragment>
      <Hero filterFunction={this.handleFilterChange} />
			<PublicationList toggleFunction={this.handleToggleChange} />
		</React.Fragment>
	)
}

export default Publications
