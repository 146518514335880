import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import List from "./screens/news/list"
import PressKit from "./screens/resources/press-kit"

const News = () => {
  return (
    <React.Fragment>
      <List />
      <PressKit />
    </React.Fragment>
  )
}
export default News

export const Head = () => (
  <Seo
        title="News"
        description="Recent news, press releases and company announcements."
        image="/meta/news.jpg"
  />
)
