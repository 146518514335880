import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"

const StyledPressKit = styled.section`

  background: ${colors.purpleDark};

  .press-kit__header {
    padding: 60px 0;
    text-align: center;
    color: ${colors.white};
    border-bottom: 1px solid ${colors.charcoal};

    ${breakpoint.medium`
      padding: 124px 0;
    `}
  }

  h2 {
    padding-bottom: 1rem;
  }

  .load-more {
    width: 100%;
    margin-top: 40px;
    padding: 0 28px;
    text-align: center;

    button {
      max-width: 320px;
      background-color: ${colors.orange};
      color: ${colors.charcoal};

      &:hover {
        background-color: ${colors.charcoal};
        color: ${colors.white};
      }
    }
    
    ${ButtonLink} {
      width: 100%;

      ${breakpoint.medium`
        width: auto;
      `}
    }
  }
`

const PressKit = () => {
  return (
    <StyledPressKit data-aos="fade-up">
      <div className="press-kit__header">
        <Container>
          <h2>Our Press Kit</h2>
          <p>Get the assets you need to talk about Visby here.</p>
          <div className="load-more">
            <ButtonLink backgroundcolor="primary" to="/resources/press-kit/">
              View Our Press Kit
            </ButtonLink>
          </div>
        </Container>
      </div>
    </StyledPressKit>
  )
}
export default PressKit
